import { useProgress } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useRef } from 'react';
import * as THREE from 'three'; // Import Three.js for geometry
import { TextGeometry } from 'three/examples/jsm/Addons.js';

function CircleLoader({position}) {
  const { progress } = useProgress();
  const loaderRef = useRef();

  // Rotate the loader for animation effect
  useFrame(() => {
    if (loaderRef.current) {
      loaderRef.current.rotation.z += 0.08; // Speed of rotation
    }
  });

  return (
    <group>
      {/* Circular loader using RingGeometry */}
      <mesh ref={loaderRef} position={position}>
        <ringGeometry args={[0.1, 0.15, 6]} /> {/* Inner and outer radius */}
        <meshBasicMaterial color="black" side={THREE.DoubleSide} />
      </mesh>
    </group>
  );
}

export default CircleLoader;
