// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import Home from "./components/Home";

// const App = () => {
//   return <Home />;
// };

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
// export default App;

import React, { useEffect, useRef } from "react";
import { VRButton, XR } from "@react-three/xr";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import Navigation from "./Navigation/Navigation";
import { GLTFLoader } from "three/examples/jsm/Addons.js";
import { AnimationMixer } from "three";

const Model = (props) => {
  const gltf = useLoader(GLTFLoader, "model/ocean1.glb"); //ocean1 ,Beach3,Pond1

  const mixer = useRef();

  useEffect(() => {
    if (gltf.animations.length) {
      mixer.current = new AnimationMixer(gltf.scene);
      gltf.animations.forEach((clip) => {
        const action = mixer.current.clipAction(clip);
        action.play();
      });
    }

    return () => mixer.current?.stopAllAction();
  }, [gltf]);

  useFrame((state, delta) => {
    mixer.current?.update(delta);
  });

  return <primitive object={gltf.scene} {...props} />;
};
const App = () => {
  return (
    <>
      <VRButton />
      <Canvas>
        <XR>
          <Model position={[0, 0, -4]} />
          <Navigation />
        </XR>
      </Canvas>
    </>
  );
};

export default App;
