import React, { useState, useEffect, useRef } from "react";
import { Canvas, useLoader } from "@react-three/fiber";
import {
  VRCanvas,
  DefaultXRControllers,
  Interactive,
  Controllers,
} from "@react-three/xr";
import { Box, RoundedBox, Sky, Text } from "@react-three/drei";
import axios from "axios";
import { BASE_URL } from "../../utilities/ApiConfig";
import { useNavigate } from "react-router-dom";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DialogBox } from "../../common/Alert";
import { useProgress } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { TextGeometry } from "three/examples/jsm/Addons.js";
import { RingGeometry } from "three";
import * as THREE from 'three';
import CircleLoader from "../../common/Loader";
function PraticeScreen() {
  const [data, setData] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [loader, setLoader] = useState(true);
  
  const [text, setText] = useState("");
  const [error, setError] = useState("");

  function Menu() {
    const [selectedOption, setSelectedOption] = useState(null);
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            BASE_URL + "api/v1/category/listcategory"
          );
          console.log("response", response.data);
          setLoader(false)
          const fields = response.data;
          // Handle the fields as needed, e.g., setData(fields);
          // console.log(fields);
          setData(fields);
          // setShowDialog(true);
          // setText("output", response.data);
        } catch (error) {
          setShowDialog(true);
          setText(error.message);
          console.error("Error fetching data:", error);
        }
      };
      if (data.length === 0) {
        fetchData();
      }
    }, []);
    const DialogBox = ({ message }) => {
      const dialogRef = useRef();
      const [hovered, setHovered] = useState(false);
      return (
        <group position={[-3, 1.6, -3]} ref={dialogRef}>
          <RoundedBox args={[1, 0.5, 0.02]} radius={0.05} smoothness={4}>
            <meshStandardMaterial color="purple" />
          </RoundedBox>
          <Text
            fontSize={0.08}
            position={[0, 0.1, 0.03]}
            color="white"
            anchorX="center"
            anchorY="middle"
          >
            {message}
          </Text>
        </group>
      );
    };
    function MenuButton({ label, value, position, onClick }) {
      const [hovered, setHovered] = useState(false);
      const buttonRef = useRef();
      return (
        <Interactive
          onClick={() => onClick(value)} // Passing the value to onClick
          onSelect={() => onClick(value)} // Also handling the select event
          onHover={() => setHovered(true)}
          onBlur={() => setHovered(false)}
          onSelectStart={() => onClick(value)} // Also handling the select event
          onSelectEnd={() => onClick(value)} // Also handling the select event
        >
          <group position={position} ref={buttonRef}>
            <RoundedBox args={[0.8, 0.18, 0.05]} radius={0.05} smoothness={4}>
              <meshStandardMaterial color={hovered ? "white" : "green"} />
              <Text
                fontSize={0.05}
                position={[0, 0, 0.05]}
                color={hovered ? "black" : "white"}
                anchorX="center"
                anchorY="middle"
              >
                {label}
              </Text>
            </RoundedBox>
          </group>
        </Interactive>
      );
    }

    const navigate = useNavigate();

    const handleMenuClick = (value) => {
      console.log("Clicked value:", value);
      setError(value);
      const id = "66eac16bdf4b3a0d0f2e2a92";
      navigate("/InterviewScreen", { state: { value } });
    };
    return (
      <group position={[0, 1.5, -1]}>
        <RoundedBox
          args={[2, 2.5, -0.2]}
          position={[0, 1.5, -3]}
          radius={0.05}
          smoothness={4}
        >
          <Text
            fontSize={0.09}
            color="#000"
            anchorX="center"
            anchorY="middle"
            position={[0, 1.2, 0]}
          >
            What field do you want to practice for?
          </Text>
          {data &&
            data.length > 0 &&
            data.map((option, index) => (
              <MenuButton
                key={option._id}
                label={option.categoryName}
                value={option._id}
                position={[0, 0.6 - index * 0.28, 0]}
                onClick={handleMenuClick}
              />
            ))}
        </RoundedBox>
        {/* {showDialog && <DialogBox message={text} />} */}
      </group>
    );
  }
  const Model = (props) => {
    const gltf = useLoader(GLTFLoader, "model/sunroof.glb");

    return <primitive object={gltf.scene} {...props} />;
  };





  return (
    <>
      <Sky sunPosition={[0, 1, 0]} />
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <Controllers />
      {loader&& <CircleLoader position={[0, 1.3, -3]}/>}
      {/* <Model position={[0, 2, -3]} /> */}
      {/* <DialogBox message={error} />; */}
      <group position={[0, -1.5, 0]}>
        <Menu />
      </group>
    </>
  );
}
export default PraticeScreen;
