import React, { useState, useEffect } from "react";
import axios from "axios";
import { Canvas, useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useNavigate } from "react-router-dom";
import { Controllers, Hands, Interactive, XR } from "@react-three/xr";
import { Text, RoundedBox, Line, Sky } from "@react-three/drei";
import * as THREE from "three";

const GraphChart = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const url =
      "https://testadmin.myhotelai.com/api/v1/mobile/ticket/userPriorityCount";
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFudG9ueUB5b3BtYWlsLmNvbSIsInR5cGUiOiJ1c2VyIiwidXNlcklkIjoiNjVjNmZhZmUzZjMzOGQyZGYyYzE3OTZlIiwicGFzc3dvcmQiOiIkMmEkMDgkQ3NpTEYwdHV3YjJtZEwuTnlJUXlNZWtBenBCamFsenhyYzdXdGdPMFc1YmxSUnB3UzRGTlciLCJhZG1pbnBhc3N3b3JkIjoiJDJhJDA4JFlGc1hGUXFKcUtyYlF1QmRRVGlTMmUvMklCQk85U0hhS2RkUkt5TG1VZmhvVm1kNDB6akJXIiwiZGV2aWNlIjoid2ViIiwiaWF0IjoxNzIyNDI1ODQ0fQ.OA8nNgJ1H1uZwzRQrg87fayu3aOSiOXTe_KvdAe8dig";

    axios
      .get(url, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        const data = transformResponseData(response.data);
        console.log("====================================");
        // console.log("responseresponseresponse", response.data);
        console.log(
          "========datadatadatadatadata============================",
          data
        );
        setData(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const transformResponseData = (response) => {
    const colors = {
      high: "red",
      medium: "yellow",
      low: "green",
      GM: "blue",
      MAINTENANCE: "orange",
      OWNER: "purple",
      AGM: "pink",
    };

    const labels = {
      high: "High",
      medium: "Medium",
      low: "Low",
      GM: "GM",
      MAINTENANCE: "Maintenance",
      OWNER: "Owner",
      AGM: "AGM",
    };

    return Object.keys(response).map((key) => ({
      label: labels[key],
      value: response[key],
      color: colors[key],
    }));
  };
  const maxValue = Math.max(...data.map((d) => d.value));

  const xAxisPoints = [
    [0, 0, 0],
    [4, 0, 0],
  ].map((point) => new THREE.Vector3(...point));

  const yAxisPoints = [
    [0, 0, 0],
    [0, 2, 0],
  ].map((point) => new THREE.Vector3(...point));

  const Model = (props) => {
    const gltf = useLoader(GLTFLoader, "model/sunroof.glb");

    return <primitive object={gltf.scene} {...props} />;
  };
  const navigate = useNavigate();
  const [hoveredback, setHoveredback] = useState(false);

  const HandleBack = () => {
    navigate("/");
  };

  return (
    <>
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      {/* <Model position={[0, 2, -3]} /> */}
      <Sky sunPosition={[0, 1, 0]} />
      <Hands />
      <Controllers />
      <group position={[0, 1.6, -3]}>
        {/* Background Box */}
        <RoundedBox args={[4, 2.2, 0.1]} position={[1, 0, -0.05]}>
          <meshStandardMaterial color="gray" opacity={0.5} transparent />
        </RoundedBox>
        <Interactive
          onHover={() => setHoveredback(true)}
          onBlur={() => setHoveredback(false)}
          onSelectStart={HandleBack}
          onSelectEnd={HandleBack}
          onSelect={HandleBack}
        >
          <RoundedBox
            args={[0.5, 0.2, 0.05]}
            position={[2.8, 1, 0.1]}
            radius={0.05}
            smoothness={4}
          >
            <meshStandardMaterial color={hoveredback ? "white" : "lightblue"} />

            <Text
              fontSize={0.1}
              position={[0, 0, 0.03]}
              color="black"
              anchorX="center"
              anchorY="middle"
            >
              Back
            </Text>
          </RoundedBox>
        </Interactive>
        <group position={[-0.5, -1, 0]}>
          {/* X Axis */}
          <Line
            points={xAxisPoints}
            color="white"
            lineWidth={1}
            position={[-0.5, -0.1, 0.1]}
          />

          {/* X Axis Labels */}
          {data.map((d, i) => (
            <Text
              key={d.label}
              position={[i * 0.5, -0.3, 0.1]}
              fontSize={0.1}
              color="black"
              anchorX="center"
              anchorY="middle"
            >
              {d.label}
            </Text>
          ))}

          {/* Y Axis */}
          <Line
            points={yAxisPoints}
            color="white"
            lineWidth={1}
            position={[-0.5, -0.1, 0.1]}
          />

          {/* Y Axis Labels */}
          {[0, 2, 4, 6, 8, 10, 12, 14, 16].map((num, i) => (
            <Text
              key={i}
              position={[-0.7, (num / maxValue) * 1.2, 0.1]}
              fontSize={0.1}
              color="black"
              anchorX="center"
              anchorY="middle"
            >
              {num}
            </Text>
          ))}

          {/* Bars */}
          {data.map((d, i) => (
            <group
              position={[i * 0.5, (d.value / maxValue) * 0.9, 0]}
              key={d.label}
            >
              <RoundedBox args={[0.18, (d.value / maxValue) * 2, 0.3]}>
                <meshStandardMaterial color={d.color} />
              </RoundedBox>
            </group>
          ))}
        </group>
      </group>
    </>
  );
};

export default GraphChart;
