import React, { useState, useRef, useEffect, Suspense } from "react";
import { Canvas, useLoader, useThree } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useFrame } from "@react-three/fiber";

import {
  Hands,
  Controllers,
  VRButton,
  XR,
  Interactive,
  useXREvent,
  useXR,
} from "@react-three/xr";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

import { useNavigate } from "react-router-dom";

import { Sky, Text, RoundedBox, useTexture } from "@react-three/drei";
import * as THREE from "three";
import { AnimationMixer } from "three";

const Home = () => {
  const [dialog, setDialog] = useState(null);

  const handleButtonClick = (message) => {
    setDialog(message);
  };

  const navigate = useNavigate();

  const NavigationButton = (navigateTo) => {
    navigate(navigateTo);
  };

  const closeDialog = () => {
    setDialog(null);
  };

  const SetCameraToModel = ({ modelPosition }) => {
    const { camera } = useThree();

    useEffect(() => {
      // Ensure modelPosition is defined and is an array
      if (modelPosition && Array.isArray(modelPosition)) {
        camera.lookAt(...modelPosition); // Spread the array to pass as individual arguments
        camera.position.set(0, 2, -5); // Adjust camera position as needed
      }
    }, [modelPosition, camera]);

    return null;
  };
  const modelPosition = [0, 6, -5];
  const modelRef = useRef();
  const ModelWithCamera = () => {
    const gltf = useLoader(GLTFLoader, "model/ocean1.glb");

    const { scene, cameras } = gltf;
    const { set } = useThree();
    console.log("cameras", cameras); // PerspectiveCamera {isObject3D: true, uuid: 'f1af5dd2-31cd-4f2b-8b07-d75c38e0f86b', name: 'Camera', type: 'PerspectiveCamera', parent: Group, …}

    useEffect(() => {
      // If the model contains a camera, set it as the active camera

      if (cameras && cameras.length > 0) {
        set({ camera: cameras[0] });
      }
    }, [cameras, set]);

    return <primitive object={scene} />;
  };
  return (
    <>
      <Suspense fallback={null}>
        {/* <ModelWithCamera
          position={[0, 10, -6]}
          rotation={[Math.PI, Math.PI, 0]}
        /> */}
        <Sky sunPosition={[0, 1, 0]} />
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
        <Hands />
        <Controllers />
        {/* <Model position={[0, 0, -4]} /> */}
        {/* <Model position={[0, -20, -4]} rotation={[Math.PI, Math.PI, 0]} /> */}
        {/* <Model ref={modelRef} /> */}
        {/* <SetCameraToModel modelRef={modelRef} /> */}
       <group position={[0,0,-2]}>
       <Leftmenu
          onButtonClick={handleButtonClick}
          onNavigate={NavigationButton}
       />
        <Centermenu onButtonClick={handleButtonClick} />
        <Rightmenu
          onButtonClick={handleButtonClick}
          onNavigate={NavigationButton}
        />
       </group>
        {/* {dialog && <DialogBox message={dialog} onClose={closeDialog} />} */}
        {/* <XRContent onButtonClick={handleButtonClick} /> */}
        {/* <NewHandRay /> */}
        {/* <WhiteHandRay /> */}
        {/* {({ Hands, Controllers }) => (
          <>
            {Hands.map((hand, index) => (
              <WhiteHandRay key={index} hand={hand} />
            ))}
            {Controllers.map((controller, index) => (
              <ControllerRay key={index} controller={controller} />
            ))}
          </>
        )} */}
      </Suspense>

      {/* </XR>
      </Canvas> */}
    </>
  );
};

function WhiteHandRay({ hand }) {
  const rayRef = useRef();

  useFrame(() => {
    if (hand) {
      const indexTip = hand.joints["index-finger-tip"];

      if (indexTip) {
        // Update ray position and direction based on hand position and orientation
        rayRef.current.position.copy(indexTip.position);
        rayRef.current.rotation.copy(hand.rotation);
      }
    }
  });

  return (
    <line ref={rayRef}>
      <bufferGeometry>
        <bufferAttribute
          attach="attributes-position"
          array={new Float32Array([0, 0, 0, 0, 0, -1])}
          count={2}
          itemSize={3}
        />
      </bufferGeometry>
      <lineBasicMaterial attach="material" color="Red" />
    </line>
  );
}

function ControllerRay({ controller }) {
  const rayRef = useRef();

  useFrame(() => {
    if (controller) {
      // Update ray position and direction based on controller position and orientation
      rayRef.current.position.copy(controller.position);
      rayRef.current.rotation.copy(controller.rotation);
    }
  });

  return (
    <line ref={rayRef}>
      <bufferGeometry>
        <bufferAttribute
          attach="attributes-position"
          array={new Float32Array([0, 0, 0, 0, 0, -1])}
          count={2}
          itemSize={3}
        />
      </bufferGeometry>
      <lineBasicMaterial attach="material" color="white" />
    </line>
  );
}

// const Model = (props) => {
//   const gltf = useLoader(GLTFLoader, "model/ocean1.glb");
//   console.log("gltf", gltf);

//   // gltf.animations
//   return <primitive object={gltf.scene} {...props} />;
// };

const Model = (props) => {
  const gltf = useLoader(GLTFLoader, "model/ocean1.glb"); //ocean1.glbBeach3

  const mixer = useRef();

  useEffect(() => {
    if (gltf.animations.length) {
      mixer.current = new AnimationMixer(gltf.scene);
      gltf.animations.forEach((clip) => {
        const action = mixer.current.clipAction(clip);
        action.play();
      });
    }

    return () => mixer.current?.stopAllAction();
  }, [gltf]);

  useFrame((state, delta) => {
    mixer.current?.update(delta);
  });

  return <primitive object={gltf.scene} {...props} />;
};

//
const LeftButton = ({ label, icon, position, onClick }) => {
  let placeholderTexture = "icons/vravatar.webp";
  const iconTexture = useTexture(icon ? icon : placeholderTexture);

  const [hovered, setHovered] = useState(false);
  const buttonRef = useRef();

  return (
    <Interactive
      onHover={() => setHovered(true)}
      onBlur={() => setHovered(false)}
      onSelect={onClick}
      onSelectStart={onClick}
      onSelectEnd={onClick}
    >
      <group position={position} ref={buttonRef}>
        <RoundedBox
          args={[0.65, 0.15, 0.05]}
          // position={[-0.6, 1.5, 0.1]}
          radius={0.05}
          smoothness={4}
        >
          <meshStandardMaterial color={hovered ? "white" : "green"} />
          <Text
            fontSize={0.05}
            position={[0, 0, 0.03]}
            color={hovered ? "black" : "white"}
            anchorX="center"
            anchorY="middle"
          >
            {label}
          </Text>
        </RoundedBox>
      </group>
    </Interactive>
  );
};

const Leftmenu = ({ onButtonClick, onNavigate }) => {
  return (
    <group position={[-2.2, 1.6, -2]}>
      {/* <group position={[0, 1.6, -2]}> */}
      <LeftPanelBackground />
      <Text
        fontSize={0.07}
        position={[0, 0.5, 0.01]}
        color="white"
        anchorX="center"
        anchorY="middle"
      >
        VirtualSpeech
      </Text>
      <LeftButton
        label="Graph"
        icon="icons/home4.png"
        position={[0, 0.3, 0]}
        // onClick={() => onButtonClick("Welcome")}
        onClick={() => onNavigate("/Graph")}
      />
      <LeftButton
        label="List Data"
        icon="icons/feautures.webp"
        position={[0, 0.1, 0]}
        // onClick={() => onButtonClick("Features Guide")}
        onClick={() => onNavigate("/list")}
      />
      <LeftButton
        label="Saved Sessions"
        icon="icons/savecolor.png"
        position={[0, -0.1, 0]}
        onClick={() => onButtonClick("Saved Sessions")}
      />
      <LeftButton
        label="Multiplayer"
        icon="icons/lockicon.png"
        position={[0, -0.3, 0]}
        onClick={() => onButtonClick("Multiplayer Practice")}
      />
      <LeftButton
        label="Log In"
        icon="icons/logout.jpg"
        position={[0, -0.5, 0]}
        onClick={() => onButtonClick("Log In")}
      />
    </group>
  );
};

function HandRay() {
  const { controllers } = useXR();
  const rayRef = useRef();
  const pointerRef = useRef();
  const [pinch, setPinch] = useState(false);

  useFrame(() => {
    controllers.forEach((controller) => {
      const hand = controller.hand;
      if (hand) {
        const indexTip = hand.joints["index-finger-tip"];
        const thumbTip = hand.joints["thumb-tip"];

        if (indexTip && thumbTip) {
          // Check for pinch gesture
          const indexPosition = indexTip.position;
          const thumbPosition = thumbTip.position;

          if (indexPosition && thumbPosition) {
            const distance = indexPosition.distanceTo(thumbPosition);
            if (distance < 0.02) {
              // Adjust threshold as needed
              setPinch(true);
              pointerRef.current.position.copy(indexPosition);
            } else {
              setPinch(false);
            }
          } else {
            console.error("Hand joint positions are undefined:", {
              indexPosition,
              thumbPosition,
            });
          }
        } else {
          console.error("Hand joints are undefined:", { indexTip, thumbTip });
        }

        // Update ray position and rotation if hand position is available
        if (hand.position && hand.rotation) {
          rayRef.current.position.copy(hand.position);
          rayRef.current.rotation.copy(hand.rotation);
        } else {
          console.error("Hand position or rotation is undefined:", {
            handPosition: hand.position,
            handRotation: hand.rotation,
          });
        }
      }
    });
  });

  return (
    <>
      <line ref={rayRef}>
        <bufferGeometry>
          <bufferAttribute
            attach="attributes-position"
            array={new Float32Array([0, 0, 0, 0, 0, -1])}
            count={2}
            itemSize={3}
          />
        </bufferGeometry>
        <lineBasicMaterial attach="material" color="white" />
      </line>
      {pinch && (
        <mesh ref={pointerRef}>
          <sphereBufferGeometry args={[0.01, 32, 32]} />
          <meshStandardMaterial color="red" />
        </mesh>
      )}
    </>
  );
}
function NewHandRay() {
  const { controllers } = useXR();
  const rayRef = useRef();
  const pointerRef = useRef();
  const [pinch, setPinch] = useState(false);

  useFrame(() => {
    controllers.forEach((controller) => {
      const hand = controller.hand;
      if (hand) {
        const indexTip = hand.joints["index-finger-tip"];
        const thumbTip = hand.joints["thumb-tip"];

        // Check for pinch gesture
        if (indexTip && thumbTip) {
          const distance = indexTip.position.distanceTo(thumbTip.position);
          if (distance < 0.02) {
            // Adjust threshold as needed
            setPinch(true);
            pointerRef.current.position.copy(indexTip.position);
          } else {
            setPinch(false);
          }
        }

        // Update ray position
        rayRef.current.position.copy(hand.position);
        rayRef.current.rotation.copy(hand.rotation);
      }
    });
  });

  return (
    <>
      <line ref={rayRef}>
        <bufferGeometry>
          <bufferAttribute
            attach="attributes-position"
            array={new Float32Array([0, 0, 0, 0, 0, -1])}
            count={2}
            itemSize={3}
          />
        </bufferGeometry>
        <lineBasicMaterial attach="material" color="white" />
      </line>
      {pinch && (
        <mesh ref={pointerRef}>
          <sphereBufferGeometry args={[0.01, 32, 32]} />
          <meshStandardMaterial color="red" />
        </mesh>
      )}
    </>
  );
}

const XRContent = ({ handleButtonClick }) => {
  const { isHandTracking, hands } = useXR();

  return (
    <>
      <Hands />
      {isHandTracking &&
        hands &&
        hands.map((hand) => (
          <HandRay
            key={hand.inputSource.handedness}
            hand={hand}
            handleButtonClick={handleButtonClick}
          />
        ))}
    </>
  );
};

const calculateCurvePosition = (index, total, radius, angleOffset) => {
  const angle = ((index - (total - 1) / 2) * angleOffset * Math.PI) / 180;
  const x = radius * Math.sin(angle);
  const y = radius * (1 - Math.cos(angle));
  const rotation = [0, angle, 0];
  return { position: [x, y, 0], rotation };
};

const Rightmenu = ({ onButtonClick, onNavigate }) => {
  return (
    <group position={[2.3, 1.6, -2]}>
      <RightPanelBackground />
      <Text
        fontSize={0.1}
        position={[0, 0.4, 0.01]}
        color="white"
        anchorX="center"
        anchorY="middle"
      >
        Warm up Exercises
      </Text>
      <group position={[0, -0.2, 0]}>
        <Button
          label="Speech Exercises "
          // icon="icons/chat.jpg"
          position={[0, 0.3, 0]}
          onClick={() => {
            onButtonClick("List Data");
            onNavigate("/list");
          }}
        />

        <Button
          label=" Google Exercises"
          // icon="icons/list.jpg"
          position={[0, 0.1, 0]}
          onClick={() => {
            onButtonClick("Graph Data");
            onNavigate("/PraticeScreen");
          }}
        />
        <Button
          label="Vocal Exercises"
          // icon="icons/people.jpg"

          position={[0, -0.1, 0]}
          onClick={() => onButtonClick("Vocal Exercises")}
        />
      </group>
    </group>
  );
};

const Centermenu = ({ onButtonClick }) => {
  return (
    <group position={[0, 1.6, -2]}>
      <CenterPanelBackground />
      <group position={[0, 0.2, 0]}>
        <Text
          fontSize={0.1}
          position={[0, 0.5, 0.01]}
          color="white"
          anchorX="center"
          anchorY="middle"
        >
          Welcome to VirtualSpeech
        </Text>
        <Text
          fontSize={0.05}
          position={[0, 0.35, 0.01]}
          color="white"
          anchorX="center"
          anchorY="middle"
        >
          Here you can practice and improve your soft skills in VR Scenarios.
        </Text>
        <Text
          fontSize={0.05}
          position={[0, 0.25, 0.01]}
          color="white"
          anchorX="center"
          anchorY="middle"
        >
          New to the app? You can learn about our features here:
        </Text>
        <Button
          label="Learn more"
          icon="icons/bulb.png"
          position={[0, 0.08, 0]}
          onClick={() => onButtonClick("Learn more")}
        />
      </group>
      <group position={[0, 0, 0]}>
        <ImageButton
          label="Practice Rooms"
          image="icons/stageavatar.jpg"
          position={[-0.9, -0.3, 0]}
          onClick={() => onButtonClick("Practice Rooms")}
        />
        <ImageButton
          label="Roleplay with AI Avatars"
          image="icons/avatar.jpg"
          position={[0, -0.3, 0]}
          onClick={() => onButtonClick("Roleplay with AI Avatars")}
        />
        <ImageButton
          label="Training and Courses"
          image="icons/vravatar.webp"
          position={[0.9, -0.3, 0]}
          onClick={() => onButtonClick("Training and Courses")}
        />
      </group>
    </group>
  );
};
const ImageButton = ({ label, image, position, onClick }) => {
  const [hovered, setHovered] = useState(false);
  const imageTexture = useTexture(image);
  const buttonRef = useRef();

  return (
    <Interactive
      onHover={() => setHovered(true)}
      onBlur={() => setHovered(false)}
      onSelect={onClick}
      onSelectStart={onClick}
      onSelectEnd={onClick}
    >
      <group position={position} ref={buttonRef}>
        <RoundedBox args={[0.8, 0.8, 0.02]} radius={0.05} smoothness={4}>
          <meshStandardMaterial color={hovered ? "lightgray" : "gray"} />
        </RoundedBox>
        {/* <sprite position={[0, 0, 0.03]} scale={[0.7, 0.4, 0.1]}>
          <spriteMaterial map={imageTexture} />
        </sprite> */}
        <mesh position={[0, 0, 0.03]}>
          <planeBufferGeometry args={[0.7, 0.4]} />
          <meshBasicMaterial map={imageTexture} />
        </mesh>

        <Text
          fontSize={0.08}
          position={[0, -0.5, 0.03]}
          color={hovered ? "green" : "white"}
          anchorX="center"
          anchorY="middle"
        >
          {label}
        </Text>
      </group>
    </Interactive>
  );
};

const LeftPanelBackground = (ArgsSize) => (
  <RoundedBox args={[0.9, 1.4, 0.01]} radius={0.05} smoothness={4}>
    <shaderMaterial
      attach="material"
      uniforms={{
        color1: { value: new THREE.Color(0.56, 0.27, 0.87) },
        color2: { value: new THREE.Color(0.26, 0.26, 0.26) },
      }}
      vertexShader={`
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `}
      fragmentShader={`
        uniform vec3 color1;
        uniform vec3 color2;
        varying vec2 vUv;
        void main() {
          vec3 color = mix(color1, color2, vUv.y);
          gl_FragColor = vec4(color, 1.0);
        }
      `}
    />
  </RoundedBox>
);

const RightPanelBackground = (ArgsSize) => (
  <RoundedBox args={[1.3, 1, 0.01]} radius={0.05} smoothness={4}>
    <shaderMaterial
      attach="material"
      uniforms={{
        color1: { value: new THREE.Color(34 / 255, 34 / 255, 42 / 255) },
        color2: { value: new THREE.Color(34 / 255, 34 / 255, 42 / 255) },
      }}
      vertexShader={`
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `}
      fragmentShader={`
        uniform vec3 color1;
        uniform vec3 color2;
        varying vec2 vUv;
        void main() {
          vec3 color = mix(color1, color2, vUv.y);
          gl_FragColor = vec4(color, 1.0);
        }
      `}
    />
  </RoundedBox>
);

const CenterPanelBackground = (ArgsSize) => (
  <>
    <RoundedBox args={[2.8, 2, 0.01]} radius={0.05} smoothness={4}>
      <shaderMaterial
        attach="material"
        uniforms={{
          color1: { value: new THREE.Color(34 / 255, 34 / 255, 42 / 255) },
          color2: { value: new THREE.Color(34 / 255, 34 / 255, 42 / 255) },
        }}
        vertexShader={`
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `}
        fragmentShader={`
        uniform vec3 color1;
        uniform vec3 color2;
        varying vec2 vUv;
        void main() {
          vec3 color = mix(color1, color2, vUv.y);
          gl_FragColor = vec4(color, 1.0);
        }
      `}
      />
    </RoundedBox>
  </>
);
const Button = ({ label, icon, position, onClick }) => {
  let placeholderTexture = "icons/vravatar.webp";
  const iconTexture = useTexture(icon ? icon : placeholderTexture);

  const [hovered, setHovered] = useState(false);
  const buttonRef = useRef();

  return (
    <Interactive
      onHover={() => setHovered(true)}
      onBlur={() => setHovered(false)}
      onSelect={onClick}
      onSelectStart={onClick}
      onSelectEnd={onClick}
    >
      <group
        position={position}
        ref={buttonRef}
        anchorX="center"
        anchorY="middle"
      >
        <RoundedBox
          args={[0.65, 0.15, 0.05]}
          // position={[-0.6, 1.5, 0.1]}
          radius={0.05}
          smoothness={4}
        >
          <meshStandardMaterial color={hovered ? "white" : "green"} />
          <Text
            fontSize={0.05}
            position={[0, 0, 0.03]}
            color={hovered ? "black" : "white"}
            anchorX="center"
            anchorY="middle"
          >
            {label}
          </Text>
        </RoundedBox>
        {/* <Text
          fontSize={0.05}
          position={[0.1, 0, 0.03]}
          color={hovered ? "black" : "white"}
          anchorX="center"
          anchorY="middle"
        >
          {label}
        </Text> */}
        {icon && (
          <mesh position={[-0.2, 0, 0.03]} scale={[0.09, 0.09, 0.09]}>
            <planeBufferGeometry args={[0.7, 0.4]} />
            <meshBasicMaterial map={iconTexture} />
          </mesh>
        )}
      </group>
    </Interactive>
  );
};

const DialogBox = ({ message, onClose }) => {
  const dialogRef = useRef();
  const [hovered, setHovered] = useState(false);

  return (
    <group position={[-1, 1.6, -0.8]} ref={dialogRef}>
      <RoundedBox args={[1, 0.5, 0.02]} radius={0.05} smoothness={4}>
        <meshStandardMaterial color="purple" />
      </RoundedBox>
      <Text
        fontSize={0.08}
        position={[0, 0.1, 0.03]}
        color="white"
        anchorX="center"
        anchorY="middle"
      >
        {message}
      </Text>
      <Interactive
        onSelect={onClose}
        onHover={() => setHovered(true)}
        onBlur={() => setHovered(false)}
      >
        <Button
          label="Close"
          icon="icons/room2.png"
          position={[0, -0.1, 0]}
          onClick={() => onClose}
        />
      </Interactive>

      {/* <RoundedBox
        args={[0.8, 0.2, 0.02]}
        radius={0.05}
        smoothness={4}
        position={[0, -0.1, 0]}
      >
        <meshStandardMaterial color={hovered ? "white" : "green"} />
      </RoundedBox>

      <Text
        fontSize={0.04}
        position={[0, -0.1, 0.03]}
        color={hovered ? "black" : "white"}
        anchorX="center"
        anchorY="middle"
      >
        Close
      </Text> */}
    </group>
  );
};

export default Home;
